import React from 'react';
import { Link } from "@reach/router"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { db, firestore } from '../firebase';
import { Helper } from '../Helper';
export default class EditMaps extends React.Component {
    constructor() {
        super();
        this.state = {
            location: {
                lat: 13.631787064094638,
                lng: 100.54314216378978,
            },
            // api_key: "AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8"
        }
    }

    componentDidMount() {
        this.fetch()
    }


    async fetch() {
        // console.log(this.props.id)
        // console.log(this.props.user_id)
        let placesRef = await db.doc("users/" + this.props.user_id + '/places/' + this.props.id);
        let places_data = await (await placesRef.get()).data();
        let location = { ...this.state.location };
        location.lat = places_data.coordinate.latitude;
        location.lng = places_data.coordinate.longitude;
        this.setState({
            location: location,
        })
    }

    render() {
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={17}
                defaultCenter={{ lat: this.state.location.lat, lng: this.state.location.lng }}
                onClick={(e) => {
                    Helper.confirmModal({
                        title: "ยืนยันการเปลี่ยนแปลง",
                        description: "ยืนยันการเปลี่ยนแปลงข้อมูลที่อยู่ ?",
                        onConfirm: async () => {
                            let location = { ...this.state.location };
                            location.lat = e.latLng.lat();
                            location.lng = e.latLng.lng();
                            let coordinate = firestore(location.lat, location.lng);
                            let placesRef = await db.doc("users/" + this.props.user_id + '/places/' + this.props.id);
                            placesRef.update({ coordinate: coordinate });
                            this.setState({
                                location: location,
                            })
                        },
                        onCancel: () => {
                            return;
                        }
                    });

                }}
            >
                {props.isMarkerShown && <Marker position={{ lat: this.state.location.lat, lng: this.state.location.lng }} />}
            </GoogleMap>
        ))
        return (
            <React.Fragment>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">แก้ไขที่อยู่</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">หน้าหลัก</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/users/"}>สมาชิก</Link></li>
                                    {
                                        this.props.user_id ?
                                            <li className="breadcrumb-item"><Link to={"/user/" + this.props.user_id}>ข้อมูลสมาชิก</Link></li>
                                            : null
                                    }
                                    <li className="breadcrumb-item active">แก้ไข</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={"content"}>
                    <MyMapComponent
                        isMarkerShown
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8&v=3.exp&libraries=geometry,drawing,places"}
                        // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `800px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </section>
            </React.Fragment>
        );
    }
}